import {React} from "react";
import "./Loader.css";


const Loader = () => {
    return(
        <div class="lds-ripple"><div></div><div></div></div>
    )
}


export default Loader;